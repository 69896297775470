<template>
  <div class="resong">
    <h1>
      <router-link to="/recommend">
        推荐歌单 <i class="iconfont icon-jiantou1"></i>
      </router-link>
    </h1>
    <!-- 歌单 -->
    <div class="item"
         v-for="(item, index) in recommendSongsList"
         :key="index"
         @mouseleave="MouseMove = false"
         @mousemove="MouseMove =  index">
      <!-- 鼠标经过显示 -->
      <div v-show="MouseMove ===  index"
           @click="toPlaylistDetail(item.id)">
        <i class="iconfont icon-bofang play_icon"
           style="cursor: pointer;"></i>
        <div :class="{ songs_top_bottom: MouseMove === index }"
             class="title text_hidden"
             :style="{zIndex: 99}">
          {{ item.copywriter }}
        </div>
      </div>
      <!-- 鼠标离开隐藏 -->
      <div :class="{songs_top_bottom: MouseMove !== index}"
           class="title playCount text_hidden"
           v-show="MouseMove !== index">
        <i class="el-icon-headset"></i>
        <span v-if="item.playCount">
          {{ item.playCount | ellipsisPlayVolume }}
        </span>
        <span v-else>
          {{ item.playcount | ellipsisPlayVolume }}
        </span>
      </div>
      <!-- 图片 -->
      <img v-lazy="item.picUrl || item.coverImgUrl"
           style="cursor: pointer;"
           @click="toPlaylistDetail(item.id)">
      <!-- 名字 -->
      <span class="content text_hidden">
        {{ item.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendSong',
  props: ['recommendSongs'],
  data () {
    return {
      MouseMove: false,
      musicList: [],
      recommendSongsList: ''
    }
  },
  methods: {
    toPlaylistDetail (id) {
      this.$router.push(`/playlist?id=${id}`)
    }
  },
  watch: {
    recommendSongs: function (newVal, oldVal) {
      if (document.documentElement.clientWidth < 600) {
        this.recommendSongsList = newVal.slice(0, 6)
      } else {
        this.recommendSongsList = newVal
      }
    }
  }
}
</script>

<style scoped lang="less">
.resong {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
  flex-flow: wrap;
  h1 {
    position: absolute;
    top: -40px;
    left: 20px;
    font-size: 20px;
    cursor: pointer;
  }
  .item {
    position: relative;
    width: 250px;
    overflow: hidden;
    margin: 10px 20px;
    .title {
      position: absolute;
      top: -20%;
      left: 0;
      width: 92%;
      height: 19px;
      font-size: 16px;
      line-height: 22px;
      -webkit-line-clamp: 1;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.5);
      padding: 5px 10px;
      border-radius: 10px 10px 0 0;
    }
    .playCount {
      text-align: right;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.01),
        rgba(0, 0, 0, 0.5)
      ) !important;
    }
    .content {
      margin: 5px 0;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    img {
      width: 250px;
      height: 250px;
      border-radius: 10px;
      overflow: hidden;
    }
  }
  .play_icon {
    position: absolute;
    bottom: 40px;
    right: 15px;
    color: #ff0000;
    font-size: 50px;
  }
}
@media screen and (max-width: 479px) {
  .resong {
    margin-top: -10px;
  }
  .resong h1 {
    top: -7vw;
    font-size: 14px;
  }
  .resong h1 i {
    font-size: 10px;
  }
  .resong .item {
    width: 33vw;
    height: 20vh;
    margin: 1vw;
  }
  .resong .item img {
    width: 34vw;
    height: 16vh;
  }
  .resong .item .content {
    margin: 1vw 0;
    font-size: 10px;
  }
  .resong .item .playCount {
    font-size: 10px;
  }
  .resong .item .title {
    width: 28.5vw;
  }
}
</style>
