<template>
  <div class="result"
       v-loading="loading">
    <div class="result-top-info">
      <h1>{{keywords}}</h1>
      <span>共找到{{total}}个结果</span>
    </div>
    <div class="tab-wrap">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="歌曲"
                     name="first">
          <div class="songs-table">
            <el-table :data="songsList"
                      stripe
                      style="width: 100%"
                      @row-click="play">

              <el-table-column type="index"
                               width="50"
                               v-if="showindex"></el-table-column>

              <el-table-column prop="songName"
                               label="音乐标题">
                <template slot-scope="scope">
                  <span style="cursor: pointer;">{{scope.row.songName}}</span>
                  <span v-if="scope.row.alia.length!=0"
                        style="color:grey;fontSize:12px;marginLeft:5px">{{scope.row.alia[0]}}</span>
                  <span class="iconfont icon-bofangMV mvIcon"
                        v-if="scope.row.mvId != 0"
                        @click="toMvDetail(scope.row.mvId)"></span>
                </template>
              </el-table-column>

              <el-table-column prop="artistInfo"
                               label="歌手"
                               width="">
                <template slot-scope="scope">
                  <div v-for="(x,i) in scope.row.artistInfo"
                       :key="i"
                       style="cursor:pointer;color:#2980b9;display:inline-block"
                       @click="toArtist(x.id)">{{x.name}}<span style="color:#606266;margin:0 5px"
                          v-show="scope.row.artistInfo.length != 1 && i!=scope.row.artistInfo.length-1">/</span></div>
                </template>
              </el-table-column>

              <el-table-column prop="album"
                               label="专辑">
                <template slot-scope="scope">
                  <span style="cursor:pointer;color:#2980b9;"
                        @click="toAlbum(scope.row.albumId)">{{scope.row.album}}</span>
                  <span class="plus"
                        title="添加至歌单"
                        @click="addToQueue(scope.row,$event)">+</span>
                </template>
              </el-table-column>

              <el-table-column prop="duration"
                               label="时长"
                               :width="dtwidth"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <el-tab-pane label="歌单"
                     name="lists">
          <recommend-song :recommend-songs="playList.slice(0,8)" />
        </el-tab-pane>

        <el-tab-pane label="MV"
                     name="third">
          <ul class="mv-list1">
            <li v-for="(item,index) in mvList"
                :key="index"
                @click="toMvDetail(item.id)">
              <div class="mv-img-wrap">
                <img v-lazy="item.cover"
                     alt="newMvs">
                <p class="iconfont icon-play play"></p>
                <p class="play-count iconfont icon-play"
                   style="margin:5px">
                  {{item.playCount | ellipsisPlayVolume}}</p>
                <p class="mv-duration"
                   style="margin:5px">
                  {{item.duration | formatDuration}}</p>
              </div>
              <div class="mv-info">
                <p class="title"
                   :title="item.name">{{item.name}}</p>
                <p class="author">{{item.artistName}}</p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="page-wrap">
      <el-pagination @current-change="handleCurrentChange"
                     background
                     :layout="paji"
                     :total="total"
                     :page-size="pageSize"
                     :current-page="page">
      </el-pagination>
    </div>

    <div class="add-ball iconfont icon-yinfu"
         v-show="showAddBall"
         ref="addBall"></div>
  </div>
</template>

<script scoped>
import { playMusicAPI, searchAPI } from '@/api/api'
import recommendSong from '@/views/individuation/children/RecommendSong'

export default {
  data () {
    return {
      MouseMove: false,
      keywords: '',
      activeName: 'first',
      songsList: [],
      playList: [],
      mvList: [],
      page: 1,
      total: 0,
      pageSize: 10,
      type: 1,
      loading: true,
      showAddBall: false,
      showindex: true,
      dtwidth: 100,
      paji: 'total,  prev, pager, next, jumper'
    }
  },
  components: { recommendSong },
  computed: {
    musicQueue () {
      return this.$store.state.musicQueue
    },
    queuePos () {
      return this.$store.state.queuePos
    }
  },
  methods: {
    toMvDetail (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    },
    toPlaylistDetail (id) {
      this.$router.push(`/playlist?id=${id}`)
    },
    toArtist (id) {
      this.$router.push(`/singerDetail?id=${id}`)
    },
    toAlbum (id) {
      this.$router.push(`/album?id=${id}`)
    },
    handleCurrentChange (page) {
      this.page = page
      this.getTableData(this.type)
    },
    addToQueue (row, e) {
      const obj = {
        id: row.id,
        imgUrl: row.imgUrl,
        duration: row.duration,
        artistInfo: row.artistInfo,
        songName: row.songName
      }

      const ids = []
      for (const item of this.musicQueue) {
        ids.push(item.id)
      }
      if (ids.indexOf(obj.id) === -1) {
        this.beginAnimation(e.x, e.y)

        setTimeout(() => {
          this.$store.commit('changeQueueStyle', 'add')
          this.$store.commit('changeMusicQueue', obj)
        }, 500)

        setTimeout(() => {
          this.$store.commit('changeQueueStyle', 'normal')
        }, 1000)
      } else {
        this.$message({
          type: 'warning',
          message: '已存在播放列表哦',
          showClose: true
        })
      }
    },
    handleClick (tab) {
      const label = tab.label
      switch (label) {
        case '歌曲':
          this.total = 0
          this.page = 1
          this.type = 1
          this.pageSize = 10
          this.getTableData()
          break
        case '歌单':
          this.total = 0
          this.page = 1
          this.type = 1000
          this.pageSize = 10
          this.getTableData(this.type)
          break
        case 'MV':
          this.total = 0
          this.page = 1
          this.type = 1004
          this.pageSize = 12
          this.getTableData(this.type)
          break
      }
    },
    play (row) {
      const id = row.id
      playMusicAPI({ id }).then((res) => {
        if (res.data.data[0].url) {
          this.songUrl = res.data.data[0].url

          const musicInfo = {
            imgUrl: row.imgUrl,
            artistInfo: row.artistInfo,
            songName: row.songName,
            id: row.id,
            duration: row.duration
          }

          this.$store.commit('changeMusicUrl', this.songUrl)
          this.$store.commit('changeMusicInfo', musicInfo)
          this.$store.commit('changeMusicStatus', false)
          this.$store.commit('changeMusicQueue', musicInfo)

          const ids = []
          for (const item of this.musicQueue) {
            ids.push(item.id)
          }
          this.$store.commit('changeNowIndex', ids.indexOf(musicInfo.id))
        } else {
          this.$message({
            showClose: true,
            message: '对不起，歌曲暂时无法播放。',
            type: 'error'
          })
        }
      })
    },
    getTableData (type = 1) {
      this.loading = true
      const params = {
        keywords: this.keywords,
        limit: this.pageSize,
        offset: (this.page - 1) * this.pageSize,
        type // 1歌曲 1000歌单 1004MV
      }
      searchAPI(params)
        .then((res) => {
          let resultList = []

          switch (type) {
            case 1:
              resultList = res.data.result.songs
              // 点击最后几页返回的songCount为10会出现bug
              if (this.total === 0) {
                this.total = res.data.result.songCount
              }
              var songsList = []

              for (const item of resultList) {
                const duration = this.$options.filters.formatDuration(item.dt)
                const song = {
                  id: item.id,
                  songName: item.name,
                  artistInfo: item.ar,
                  album: item.al.name,
                  albumId: item.al.id,
                  mvId: item.mv,
                  imgUrl: item.al.picUrl,
                  alia: item.alia,
                  copyright: item.copyright,
                  duration
                }
                songsList.push(song)
              }
              this.songsList = songsList
              break
            case 1000:
              resultList = res.data.result.playlists
              // 点击最后几页返回的songCount为10会出现bug
              if (this.total === 0) {
                this.total = res.data.result.playlistCount
              }
              this.playList = resultList
              break
            case 1004:
              resultList = res.data.result.mvs
              // 点击最后几页返回的songCount为10会出现bug
              if (this.total === 0) {
                this.total = res.data.result.mvCount
              }
              this.mvList = resultList
              break
          }
        })
        .then(() => {
          this.loading = false
        })
    },
    beginAnimation (x, y) {
      this.showAddBall = true
      const endX = this.queuePos.left
      const endY = this.queuePos.top

      const el = document.querySelector('.add-ball')
      el.style.left = x + 'px'
      el.style.top = y + 'px'

      setTimeout(() => {
        el.style.left = endX + 'px'
        el.style.top = endY + 'px'
      }, 0)

      setTimeout(() => {
        this.showAddBall = false
      }, 500)
    }
  },
  created () {
    this.keywords = this.$route.query.keywords
    this.getTableData()
  },
  watch: {
    $route (newVal) {
      this.keywords = newVal.query.keywords
      this.total = 0
      this.page = 1
      this.getTableData(this.type)
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.showindex = false
      this.dtwidth = 60
      this.paji = 'prev, jumper, next'
    }
  }
}
</script>

<style scoped lang="less">
.result {
  margin: 0 auto;
  padding: 20px;
  ul {
    list-style: none;
  }
  .result-top-info {
    margin-top: 20px;
    line-height: 30px;
    display: flex;
    border-bottom: 2px solid #ff0000;
    margin-bottom: 10px;
    h1 {
      font-size: 36px;
    }
    span {
      margin: 14px 0 0 20px;
      font-size: 14px;
      color: grey;
    }
  }
  .tab-wrap {
    .songs-table {
      .el-table__row:hover .plus {
        display: block;
      }
      .mvIcon {
        color: #ff0000;
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .mv-list1 {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        width: 23%;
        margin-bottom: 20px;
        margin-right: 20px;
      }
      .mv-img-wrap {
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 165px;
          border-radius: 20px;
        }
      }
    }
  }
  .page-wrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .add-ball {
    position: fixed;
    color: #ff0000;
    transition: top 0.5s ease-in, left 0.5s linear;
    z-index: 9;
  }
}

.plus {
  padding: 10px;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 25px;
  position: absolute;
  left: -80px;
  top: 0;
  font-weight: bold;
  cursor: pointer;
  display: none;
}

/* 歌单Tab */

/* MVTabs */

.mv-img-wrap:hover .play::before {
  opacity: 1;
}

.mv-img-wrap .play::before {
  content: '\e665';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  opacity: 0;
  transition: 0.5s;
  cursor: pointer;
}

.play-count {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  font-size: 14px;
}

.play-count::before {
  margin-right: 5px;
}

.mv-duration {
  position: absolute;
  bottom: 10px;
  right: 5px;
  color: #fff;
  font-size: 14px;
}

.mv-info {
  p {
    margin: 5px 0;
  }
  p.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
  }
  .author {
    color: #a5a1a1;
    font-size: 12px;
    margin-top: 5px;
  }
  .songs_top_bottom {
    animation: songs_top_bottom 0.5s linear forwards;
  }
}

@media screen and (max-width: 479px) {
  .result {
    padding: 2vw;
  }
  .result .result-top-info {
    margin-top: 2vw;
  }
  .result .result-top-info h1 {
    font-size: 27px;
  }
  /deep/ .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .el-pagination {
    margin: 2vw 0;
  }
  .result .page-wrap {
    margin-top: 1vw;
  }
  .result .tab-wrap .mv-list1 {
    justify-content: space-evenly;
  }
  .result .tab-wrap .mv-list1 li {
    width: 36vw;
    margin: 0 2vw 3vw 0;
  }
  .result .tab-wrap .mv-list1 .mv-img-wrap img {
    height: 24vw;
  }
  .mv-duration {
    bottom: 0.5vw;
    right: 1.5vw;
    font-size: 12px;
  }
  .play-count {
    top: 0.5vw;
    right: 1.5vw;
    font-size: 12px;
  }
  .mv-info p.title {
    font-size: 14px;
    margin-left: 2vw;
  }
  .mv-info .author {
    margin-left: 2vw;
  }
}
</style>
